import { TochigiLayout } from '../layout/TochigiLayout'

import { useFetchReportsQuestionnaire } from '../../providers/TochigiProvider'
import { useEffect, useState } from 'react'
import QuestionnaireBarChart from '../components/QuestionnaireBarChart'
import { Select } from 'antd'
import { makeYearName } from '../../utils'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'

const { Option } = Select

export type Questionnaire = {
  question: string
  dataKey: string
  answers: string[]
}

export const questionnaires: Questionnaire[] = [
  {
    question: '運動部やスポーツクラブへの所属状況',
    dataKey: 'q1',
    answers: ['所属している', '所属していない'],
  },
  {
    question: '運動・スポーツの実施状況',
    dataKey: 'q2',
    answers: [
      'ほとんど毎日(週3日以上)',
      'ときどき(週1〜2日程度)',
      'ときたま(月1〜3日程度)',
      'しない',
    ],
  },
  {
    question: '1日の運動・スポーツの実施時間',
    dataKey: 'q3',
    answers: [
      '30分未満',
      '30分以上1時間未満',
      '1時間以上2時間未満',
      '2時間以上',
    ],
  },
  {
    question: '小学校入学前はどのくらい外で体を動かす遊びをしていましたか',
    dataKey: 'q4',
    answers: ['週に6日以上', '週に4~5日', '週に2~3日', '週に1日以下'],
  },
  {
    question: '朝食の有無',
    dataKey: 'q5',
    answers: ['毎日食べる', '時々欠かす', 'まったく食べない'],
  },
  {
    question: '1日の睡眠時間',
    dataKey: 'q6',
    answers: ['6時間未満', '6時間以上8時間未満', '8時間以上'],
  },
  {
    question: 'ぐっすり眠ることができますか',
    dataKey: 'q7',
    answers: ['週に6日以上', '週に4~5日', '週に2~3日', '週に1日以下'],
  },
  {
    question: '1日のテレビ(テレビゲーム含む)の視聴時間',
    dataKey: 'q8',
    answers: [
      '1時間未満',
      '1時間以上2時間未満',
      '2時間以上3時間未満',
      '3時間以上',
    ],
  },
  {
    question: 'なんでも最後までやりとげたいと思う',
    dataKey: 'q9',
    answers: [
      'とてもそう思う',
      'まあそう思う',
      'あまりそう思わない',
      'まったくそう思わない',
    ],
  },
]

type ReportQuestionnaireByGrade = {
  schoolCategory: string
  grade: number
  countByAnswer: number[]
}

type ReportQuestionnaireByGender = {
  gender: number
  grades: ReportQuestionnaireByGrade[]
}

export type ReportQuestionnaire = {
  key: string
  genders: ReportQuestionnaireByGender[]
}

const schools = [
  'elementarySchoolReport',
  'juniorHighSchoolReport',
  'highSchoolReport',
] as const

const ReportQuestionnaireTable = ({ year }: { year: number }) => {
  const { value: resArr, loading } = useFetchReportsQuestionnaire(year)

  const [dataForGraph, setDataForGraph] = useState<ReportQuestionnaire[]>([])

  useEffect(() => {
    if (!resArr || !resArr[0] || !resArr[1]) {
      setDataForGraph([])
      return
    }

    const userGenders = [1, 2]
    const _data: any[] = questionnaires.map((q) => {
      const genders: any[] = [
        {
          gender: 1,
          grades: [],
        },
        {
          gender: 2,
          grades: [],
        },
      ]
      userGenders.forEach((g) => {
        const d = g === 1 ? resArr[0] : resArr[1]
        const destGrades = genders.find((gg) => gg.gender === g)
        if (!destGrades) return
        schools.forEach((s) => {
          if (!d) return
          const targetData = d[s]

          targetData.reports?.forEach((r) => {
            const { grade, countByAnswer } = r
            if (!countByAnswer || !countByAnswer[q.dataKey]) return
            let answers = countByAnswer[q.dataKey]

            const total = answers.reduce((p, c) => p + c.v, 0)
            answers = answers.map((v) => ((v.v / total) * 100).toFixed(1))
            let schoolCategory = 'ELEMENTARY_SCHOOL'
            if (s === 'juniorHighSchoolReport')
              schoolCategory = 'JUNIOR_HIGH_SCHOOL'
            else if (s === 'highSchoolReport') schoolCategory = 'HIGH_SCHOOL'

            destGrades.grades.push({
              schoolCategory,
              grade: grade!,
              countByAnswer: answers,
            })
          })
        })
      })
      return {
        key: q.dataKey,
        genders,
      }
    })
    setDataForGraph(_data)
  }, [resArr, loading])

  if (loading) {
    return <Loading />
  }

  if (dataForGraph.length === 0) {
    return <NoData />
  }

  return (
    <>
      {dataForGraph.map((q) => {
        const qq = questionnaires.find((v) => v.dataKey === q.key)
        if (!qq) return null
        return (
          <QuestionnaireBarChart
            questionnaire={qq}
            report={q}
            key={q.key}
            forPrinting={false}
          />
        )
      })}
    </>
  )
}

export function ReportQuestionnairePage() {
  const [year, setYear] = useState(2024)
  return (
    <TochigiLayout>
      <div className="space-y-4 pb-8">
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2022}>2022 ({makeYearName(2022)})</Option>
          <Option value={2023}>2023 ({makeYearName(2023)})</Option>
          <Option value={2024}>2024 ({makeYearName(2024)})</Option>
        </Select>
      </div>
      <ReportQuestionnaireTable key={year} year={year} />
    </TochigiLayout>
  )
}
