import { Fragment, useEffect, useState } from 'react'

import { TochigiLayout } from '../layout/TochigiLayout'

import { useFetchReportsCrossAnalysis } from '../../providers/TochigiProvider'
import { Radio, Select } from 'antd'
import { Questionnaire, questionnaires } from './ReportQuestionnairePage'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'
import { makeYearName } from '../../utils'

const { Option } = Select

const scoreTypes = {
  height: '身長',
  weight: '体重',
  grip: '握力',
  sitUps: '上体起こし',
  bending: '長座体前屈',
  sideJump: '反復横跳び',
  shuttleRun: 'シャトルラン',
  sprintRun: '50m走',
  standingJump: '立ち幅跳び',
  handballThrow: 'ボール投げ',
  totalScore: '得点合計',
}

const schoolCategories = [
  'ELEMENTARY_SCHOOL',
  'JUNIOR_HIGH_SCHOOL',
  'ALL_DAY_HIGH_SCHOOL',
]

const styleForReport = {
  fontSize: 12,
  width: 80,
}
const styleForScoreType = {
  fontSize: 10,
  width: 80,
}

const TableHeader = ({ className }: { className: string }) => {
  return (
    <thead>
      <tr>
        <th className={className} style={styleForReport}>
          校種
        </th>
        <th className={className} style={styleForReport}>
          学年
        </th>
        <th className={className} style={{ fontSize: 12, width: 128 }}>
          設問
        </th>
        <th className={className} style={styleForReport}>
          標本数
        </th>
        <>
          {Object.keys(scoreTypes).map((k) => (
            <th className={className} style={styleForScoreType} key={k}>
              {scoreTypes[k]}
            </th>
          ))}
        </>
      </tr>
    </thead>
  )
}

type TableBodyProps = {
  className: string
  question: Questionnaire
  data: any
  valueType: string
  schoolCategory: string
}

const TableBody = ({
  className,
  question,
  data,
  valueType,
  schoolCategory,
}: TableBodyProps) => {
  if (!data || !question) {
    return null
  }
  const getCategoryName = (s: string): string => {
    switch (s) {
      case 'ELEMENTARY_SCHOOL':
        return '小学校'
      case 'JUNIOR_HIGH_SCHOOL':
        return '中学校'
      case 'ALL_DAY_HIGH_SCHOOL':
        return '高校'
    }
    return ''
  }
  const answerCount = question.answers.length
  const gradeCount = schoolCategory === 'ELEMENTARY_SCHOOL' ? 6 : 3
  const rowSpan = (answerCount + 1) * gradeCount + 1

  let scores = data.grades!.filter((d) => d.school_category === schoolCategory)
  // scores = scores.slice().sort((a, b) => a.grade! - b.grade!)

  return (
    <tbody>
      <tr>
        <th className={className} rowSpan={rowSpan}>
          {getCategoryName(schoolCategory)}
        </th>
      </tr>
      <>
        {scores.map((g) => (
          <Fragment key={`${g.schoolCategory}_${g.grade}`}>
            <tr>
              <th className={className} rowSpan={answerCount + 1}>
                {g.grade}
              </th>
            </tr>
            <>
              {g.answers!.map((a, idx) => {
                const target =
                  valueType === 'average' ? a.averages : a.deviations

                const scores = Object.keys(scoreTypes).map(
                  (type) => target!.find((t) => t.scoreType === type)!.score!,
                )
                let answer: any = a.answer!
                answer = questionnaires
                  .find((i) => i.dataKey === question.dataKey)!
                  .answers![answer - 1].replace(/\(.*\)/i, '')

                return (
                  <tr key={idx}>
                    <td className={className} style={{ fontSize: 10 }}>
                      {answer}
                    </td>
                    <td className={className}>{a.totalCount}</td>
                    <>
                      {scores.map((score, idx) => (
                        <td className={className} key={idx}>
                          {(score ?? 0).toFixed(2)}
                        </td>
                      ))}
                    </>
                  </tr>
                )
              })}
            </>
          </Fragment>
        ))}
      </>
    </tbody>
  )
}

type TableProps = {
  gender: number
  valueType: string
  question: Questionnaire | undefined
  year: number
}

const ReportCrossAnalysisTable = ({
  gender,
  valueType,
  question,
  year,
}: TableProps) => {
  const { data, loading } = useFetchReportsCrossAnalysis(year)

  if (loading) {
    return <Loading />
  }

  const genderIdInResponseArr: 0 | 1 = gender === 1 ? 0 : 1

  if (!data || !data[genderIdInResponseArr]) {
    return <NoData />
  }

  if (!question) {
    return <NoData />
  }

  const reportData = data[genderIdInResponseArr].find(
    (d) => d.dataKey === question.dataKey,
  )

  if (!reportData) {
    return <NoData />
  }

  return (
    <>
      {schoolCategories.map((s) => (
        <div key={s} style={{ marginBottom: 10, pageBreakAfter: 'always' }}>
          <table className="table border-collapse">
            <TableHeader
              className={
                gender === 1 ? 'table-border-male' : 'table-border-female'
              }
            />
            <TableBody
              className={
                gender === 1 ? 'table-border-male' : 'table-border-female'
              }
              question={question}
              data={reportData!}
              schoolCategory={s}
              valueType={valueType}
            />
          </table>
        </div>
      ))}
    </>
  )
}

export function ReportCrossAnalysisPage() {
  const [question, setQuestion] = useState<Questionnaire>()
  const [gender, setGender] = useState(1)
  const [valueType, setValueType] = useState('average')
  const [selectedQuestion, setSelectedQuestion] = useState('q1')

  const handleChangeValueType = (e) => setValueType(e.target.value)
  const handleChangeQuestion = (v) => setSelectedQuestion(v)

  useEffect(() => {
    const q = questionnaires.find((q) => q.dataKey === selectedQuestion)
    if (q) {
      setQuestion(q)
    }
  }, [selectedQuestion])

  const [year, setYear] = useState(2024)

  return (
    <TochigiLayout>
      <div className="space-y-4 pb-8">
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2022}>2022 ({makeYearName(2022)})</Option>
          <Option value={2023}>2023 ({makeYearName(2023)})</Option>
          <Option value={2024}>2024 ({makeYearName(2024)})</Option>
        </Select>
      </div>
      <div>
        <Select value={gender} onChange={(v) => setGender(v)}>
          <Option value={1}>男子</Option>
          <Option value={2}>女子</Option>
        </Select>
      </div>

      <div>
        <Radio.Group onChange={handleChangeValueType} value={valueType}>
          <Radio value="average" className="dark:text-white">
            平均値
          </Radio>
          <Radio value="deviation" className="dark:text-white">
            標準偏差
          </Radio>
        </Radio.Group>
      </div>

      <div>
        <Select
          value={selectedQuestion}
          onChange={handleChangeQuestion}
          style={{ width: 320 }}
        >
          {questionnaires.map((q) => (
            <Option value={q.dataKey} key={q.dataKey}>
              {q.question}
            </Option>
          ))}
        </Select>
      </div>

      <ReportCrossAnalysisTable
        key={year}
        gender={gender}
        valueType={valueType}
        question={question}
        year={year}
      />
    </TochigiLayout>
  )
}
