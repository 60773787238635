import { Select } from 'antd'
import { useEffect, useState } from 'react'

import { useFetchReportsCount } from '../../providers/TochigiProvider'
import { makeYearName } from '../../utils'
import { TochigiLayout } from '../layout/TochigiLayout'
import { Loading } from '../shared/Loading'
import { NoData } from '../shared/NoData'

const { Option } = Select

const ReportTableData = ({ data }) => {
  if (!data) return null

  let totalTotalCount = 0,
    totalSamplingCount = 0
  const formatter = new Intl.NumberFormat('ja-JP')
  const genderReports = [data.male, data.female].map((d) => {
    if (!d) return null
    const { totalCount, samplingCount } = d
    if (!totalCount || !samplingCount) return null
    totalTotalCount += totalCount
    totalSamplingCount += samplingCount
    return (
      <>
        <td className="table-border-yellow">{formatter.format(totalCount)}</td>
        <td className="table-border-yellow">
          {formatter.format(samplingCount)}
        </td>
        <td className="table-border-yellow">
          {((samplingCount / totalCount) * 100).toFixed(1)}%
        </td>
      </>
    )
  })
  return (
    <tr className="table-border-yellow">
      <td className="table-border-yellow">
        {data.grade !== 0 ? `${data.grade}学年` : ''}
      </td>
      {genderReports}
      <td className="table-border-yellow">
        {formatter.format(totalTotalCount)}
      </td>
      <td className="table-border-yellow">
        {formatter.format(totalSamplingCount)}
      </td>
      <td className="table-border-yellow">
        {((totalSamplingCount / totalTotalCount) * 100).toFixed(1)}%
      </td>
    </tr>
  )
}

const schoolKeys = [
  'elementarySchoolReport',
  'juniorHighSchoolReport',
  'allDayHighSchoolReport',
  'fixTimeHighSchoolReport',
]
const genders = ['male', 'female']

const ReportCountTable = () => {
  const [year, setYear] = useState(2024)

  /**
   * useAsync is reusing Logic with Custom Hooks
   * @link [Reusing Logic with Custom Hooks](https://react.dev/learn/reusing-logic-with-custom-hooks)
   */
  const { loading, value: data } = useFetchReportsCount(year)

  const [totalReport, setTotalReport] = useState({
    schoolCount: 0,
    reports: [],
  })

  useEffect(() => {
    if (!data) return
    const totalReport = {
      grade: 0,
      male: {
        totalCount: 0,
        samplingCount: 0,
      },
      female: {
        totalCount: 0,
        samplingCount: 0,
      },
    }
    const totalData = {
      schoolCount: 0,
      reports: [totalReport],
    }
    schoolKeys.forEach((key) => {
      const d = data[key]
      if (!d) return
      totalData.schoolCount += d.schoolCount
      genders.forEach((gender) => {
        d.reports.forEach((report) => {
          const r = report[gender]
          totalReport[gender].totalCount += r.totalCount
          totalReport[gender].samplingCount += r.samplingCount
        })
      })
    })
    setTotalReport(totalData)
  }, [data])

  const {
    elementarySchoolReport,
    juniorHighSchoolReport,
    allDayHighSchoolReport,
    fixTimeHighSchoolReport,
  } = data || {}

  return (
    <>
      <div className="space-y-4 pb-8">
        <Select value={year} onChange={(v) => setYear(v)}>
          <Option value={2022}>2022 ({makeYearName(2022)})</Option>
          <Option value={2023}>2023 ({makeYearName(2023)})</Option>
          <Option value={2024}>2024 ({makeYearName(2024)})</Option>
        </Select>
      </div>
      {loading && <Loading />}
      {elementarySchoolReport && !loading && (
        <table className="table border-collapse">
          <thead>
            <tr className="border border-white">
              <th rowSpan={2} className="table-border-yellow">
                校種
              </th>
              <th rowSpan={2} className="table-border-yellow">
                校数
              </th>
              <th rowSpan={2} className="table-border-yellow">
                学年
              </th>
              <th colSpan={3} className="table-border-yellow">
                男子
              </th>
              <th colSpan={3} className="table-border-yellow">
                女子
              </th>
              <th colSpan={3} className="table-border-yellow">
                合計
              </th>
            </tr>
            <tr className="border border-white">
              <th className="table-border-yellow">在籍人数</th>
              <th className="table-border-yellow">実施人数</th>
              <th className="table-border-yellow">実施率</th>
              <th className="table-border-yellow">在籍人数</th>
              <th className="table-border-yellow">実施人数</th>
              <th className="table-border-yellow">実施率</th>
              <th className="table-border-yellow">在籍人数</th>
              <th className="table-border-yellow">実施人数</th>
              <th className="table-border-yellow">実施率</th>
            </tr>
          </thead>
          <tbody>
            <tr className="table-border-yellow">
              <th rowSpan={7} className="table-border-yellow">
                小学校
              </th>
              <td rowSpan={7} className="table-border-yellow">
                {elementarySchoolReport?.schoolCount}
              </td>
            </tr>
            {elementarySchoolReport?.reports?.map((data) => (
              <ReportTableData data={data} />
            ))}
            <tr className="table-border-yellow">
              <th rowSpan={4} className="table-border-yellow">
                中学校
              </th>
              <td rowSpan={4} className="table-border-yellow">
                {juniorHighSchoolReport?.schoolCount}
              </td>
            </tr>
            {juniorHighSchoolReport?.reports?.map((data) => (
              <ReportTableData data={data} />
            ))}
            <tr className="table-border-yellow">
              <th rowSpan={4} className="table-border-yellow">
                高等学校
              </th>
              <td rowSpan={4} className="table-border-yellow">
                {allDayHighSchoolReport?.schoolCount}
              </td>
            </tr>
            {allDayHighSchoolReport?.reports?.map((data) => (
              <ReportTableData data={data} />
            ))}
            <tr className="table-border-yellow">
              <th rowSpan={5} className="table-border-yellow">
                高等学校
                <br />
                (定時制)
              </th>
              <td rowSpan={5} className="table-border-yellow">
                {fixTimeHighSchoolReport?.schoolCount}
              </td>
            </tr>
            {fixTimeHighSchoolReport?.reports?.map((data) => (
              <ReportTableData data={data} />
            ))}
            <tr className="table-border-yellow">
              <th rowSpan={2} className="table-border-yellow">
                合計
              </th>
              <td rowSpan={2} className="table-border-yellow">
                {totalReport?.schoolCount}
              </td>
            </tr>
            {totalReport?.reports?.map((data) => (
              <ReportTableData data={data} />
            ))}
          </tbody>
        </table>
      )}
      {!elementarySchoolReport && !loading && <NoData />}
    </>
  )
}

export function ReportCountPage() {
  return (
    <TochigiLayout>
      <ReportCountTable />
    </TochigiLayout>
  )
}
