export const schoolCategories = [
  {
    code: 'B1',
    name: '小学校・義務教育学校（前期）',
    meta: 'ELEMENTARY_SCHOOL',
  },
  {
    code: 'C1',
    name: '中学校・義務教育学校（後期）',
    meta: 'MIDDLE_SCHOOL',
  },
  {
    code: 'D1',
    name: '高校（全日制・定時制）',
    meta: 'HIGH_SCHOOL',
  },
]

/**
 * @link [Reiwa_era](https://en.wikipedia.org/wiki/Reiwa_era)
 * @param y - year
 * @returns 
 */
export const makeYearName = (y: number): string => {
  if (y >= 2019) {
    return `R${y - 2018}`
  }
  return `H${y - 1988}`
}

export const schoolGrades = [
  { name: '小学校 1年生', value: 6 },
  { name: '小学校 2年生', value: 7 },
  { name: '小学校 3年生', value: 8 },
  { name: '小学校 4年生', value: 9 },
  { name: '小学校 5年生', value: 10 },
  { name: '小学校 6年生', value: 11 },
  { name: '中学校 1年生', value: 12 },
  { name: '中学校 2年生', value: 13 },
  { name: '中学校 3年生', value: 14 },
  { name: '高等学校 1年生', value: 15 },
  { name: '高等学校 2年生', value: 16 },
  { name: '高等学校 3年生', value: 17 },
]
